
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import TrainningInstituteModal from '@/layout/header/partials/trainning_institute/trainning_institute_drawer.vue';
// import TargetListModal from "@/components/modals/forms/association/TargetListModal.vue";
import OrganizationModal from '@/components/modals/forms/settings/OrganizationModal.vue';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'institute-list',
  components: {
    TrainningInstituteModal,
    Form,
    Datatable,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      actionApprove: false,
      actionEdit: false,
      actionDelete: false,
      data: {},
      batch: {
        tranche_id: '',
        association_info_id: [] as any,
        institute_type_id: '',
        district: '',
        status: '',
      } as any,
      association: [] as any,
       TpartnerData: [] as any,
      instituteTypes: [],
      industry: [],
      optionsTPartner: [] as any,
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      instituteList: [],
      active_status: 0,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '300px',
        },
        {
          name: 'SL',
          key: 'sl',
          sortable: false,
        },
        {
          name: 'logo',
          key: 'logo',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Unique Id',
          key: 'uniqueId',
          sortable: false,
          width: '150px',
        },

        {
          name: 'Short name',
          key: 'short_name',
          sortable: true,
        },
        {
          name: 'Full Name',
          key: 'full_name',
          sortable: true,
          width: '200px',
        },

        {
          name: 'Telephone',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },

        {
          name: 'Website',
          key: 'website',
          sortable: true,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: false,
        },
      ],
      adminCheck: false,
      loading: false,
      load: false,
      tranches: [],
      componentKey: 0,
      instituteInfos: false,
      TpartnerInfos:[] as any,
      showinstitutionList: false,
      showSearchBtn: false,
      showtrainingProviderNotice: false,
      api_url: '',

      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
    };
  },
  async created() {
    await this.actioncheck();
    await this.getTranche();
    await this.getDistrict();
    this.emitter.on('institute-updated', async () => {
      await this.institutionList();
    });
    await this.getTrainningInstituteTypes();
    this.api_url = this.VUE_APP_API_URL;
    // await this.getEntityTypes();
    // await this.getEntityInfos();
    await this.getAssociation();
    this.checkAdmin();
  },
  
  methods: {
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == 'Training Institute') {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Approve') {
                this.actionApprove = true;
              }
              if (actions[j].action_name === 'Edit') {
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Delete') {
                this.actionDelete = true;
              }
            }
          }
        }
      }
    },
    filterEntity(entity) {
      this.associationData = [];
      // select korai dibo sob  first index shift kore
      this.associationData.push(entity);
      if (entity.includes('select')) {
        this.associationData = [] as any;
        this.associationData.push(this.tempId);
        this.association.shift();

        this.association.forEach((val, index) => {
          if (val.id != 'select') {
            this.batch.association_info_id.push(val.id);
          }
        });

        // association khali kore push korabo unselect status
        this.association = [];
        this.association.push(this.UnselectObj);
      }
      if (entity.includes('unselect')) {
        this.associationData = [];
        //unshift koro select obj
        this.association = [];

        this.association = this.tempAssociation;
        this.association.unshift(this.selectObj);
        this.batch.association_info_id = '';
        // tempassociation theke association vorbo
        //batch.association id empty
      }
    },

    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    checkAdmin() {
      if (VueCookieNext.getCookie('_seip_entity_type') == 1004) {
        let user = VueCookieNext.getCookie('_seip_user');
        console.log(
          user.employee.entity_organogram[0].entitytyperole.role_title
        );
        if (
          user.employee.entity_organogram[0].entitytyperole.role_title ==
          'Admin'
        ) {
          this.adminCheck = true;
        }
      }
    },
    async getDistrict() {
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.districts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
     async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
    async getTrainningInstituteTypes() {
      await ApiService.get('configurations/institute_type/list')
        .then((response) => {
          this.instituteTypes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {
      //form submit
    },

    async institutionList() {
      let entity_id = this.batch.association_info_id;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } 
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      if (this.batch.association_info_id.length > 0) {
        this.load = true;
        await ApiService.get(
          'institute/list?entity_id=' +
            entity_id +
            '&tranche_id=' +
            this.batch.tranche_id +
            '&training_institute_type=' +
            this.batch.institute_type_id +
            '&district=' +
            this.batch.district +
            '&active_status=' +
            this.batch.status +
            '&institute_info_id=' +
            institute_info_id
        )
          .then((response) => {
            this.instituteList = [];
            this.instituteList = response.data.data;
            this.showinstitutionList = true;
            this.componentKey += 1;
            this.load = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Please Select Training Partner!',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    async printPdf() {
      let entity_id = this.batch.association_info_id;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();

      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('training_institute_type', this.batch.institute_type_id);
      formData.set('district', this.batch.district);
      formData.set('active_status', this.batch.status);
      formData.set('institute_info_id', institute_info_id);


      axios
        .post(`${this.VUE_APP_API_URL}/api/institute/institute_print`, formData)
        .then(response => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },

    async downloadPdf() {
        ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });

      let entity_id = this.batch.association_info_id;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();

      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('training_institute_type', this.batch.institute_type_id);
      formData.set('district', this.batch.district);
      formData.set('active_status', this.batch.status);
      formData.set('institute_info_id', institute_info_id);

      // console.log(JSON.stringify(this.st));

      await ApiService.post('institute/institute_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async exportTrainee() {
        ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = this.batch.association_info_id;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      } 
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      let formData = new FormData();

      formData.set('entity_id', entity_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('training_institute_type', this.batch.institute_type_id);
      formData.set('district', this.batch.district);
      formData.set('active_status', this.batch.status);
      formData.set('institute_info_id', institute_info_id);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/institute/institute_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'InstituteReport.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },

    searchBtnActive() {
      this.showSearchBtn = true;
    },

    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    edit(data) {
      this.emitter.emit('institute-edit-modal-data', data);
    },
    view(institute) {
      this.data = institute;
    },

    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('institute/delete/' + `${id}`)
            .then((response) => {
              if (response.data.status == 'error') {
              this.load = false;
              Swal.fire({
                title: 'Error!',
                html: response.data.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
              } else {
                Swal.fire('Deleted!', response.data.data, 'success').then(() => {
                   this.emitter.emit('institute-updated', true);
                });
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Suspend(id) {
      Swal.fire({
        title: 'Are you sure you want to suspend it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, suspend!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'institute/status-change?institute_id=' + id + '&active_status=4'
          )
            .then((response) => {
              //this.courseInfo();
              Swal.fire('Suspended!', response.data.message, 'success');
              this.emitter.emit('institute-updated', true);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Active(id) {
      Swal.fire({
        title: 'Are you sure you want to Active it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Activated!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'institute/status-change?institute_id=' + id + '&active_status=1'
          )
            .then((response) => {
              //this.courseInfo();
              Swal.fire('Activated!', response.data.message, 'success');
              this.emitter.emit('institute-updated', true);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Inactive(id) {
      Swal.fire({
        title: 'Are you sure you want to Inactive it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Inactivate!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            'institute/status-change?institute_id=' + id + '&active_status=3'
          )
            .then((response) => {
              //this.courseInfo();
              Swal.fire('Inactivated!', response.data.message, 'success');
              this.emitter.emit('institute-updated', true);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const InstituteSchema = [];
    return {
      InstituteSchema,
    };
  },
});
